<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <AMonthPicker
                    v-model:value="state.params.month"
                    :allow-clear="false"
                    placeholder="Pilih Bulan"
                    style="width:100%;"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- row 1 -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-3 col-md-12" style="display:flex;flex-direction:column;">
                <div class="card">
                    <div class="card-body text-center">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.total.loading">
                            <strong>Registered Salesman</strong>
                            <h1 class="h1">{{
                                state.total.registered_salesman
                            }}</h1>
                        </ASkeleton>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body text-center">
                        <ASkeleton
                            active
                            :paragraph="{ rows: 2 }"
                            :loading="state.total.loading">
                            <strong>Total Salesman Yang Sudah Visit</strong>
                            <h1 class="h1">{{
                                state.total.visit_salesman
                            }}</h1>
                        </ASkeleton>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-md-12 mt-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Salesman Yang Sudah Visit & Realisasinya</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <!--  -->
                            </div>
                        </div>
                        
                        <ChartVisitComponent
                            v-if="!state.isFetching"
                            :endpoint="state.endpoints.row1Right"
                            :params="queryParams()"
                            is-hover-label/>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- row 2 -->
         <div class="card mt-4">
            <div class="card-body">
                <div class="row justify-content-end">
                    <div class="col-lg-6 col-md-12">
                        <strong>Salesman Yang Sudah Visit & % Kelengkapan Surveynya</strong>
                    </div>
                    <div class="col-lg-6 col-md-12 text-right mt-auto">
                        <!--  -->
                    </div>
                </div>

                <ChartPercentageComponent
                    v-if="!state.isFetching"
                    :endpoint="state.endpoints.row2Current"
                    :params="queryParams()"
                    is-hover-label/>
            </div>
        </div>
        
        <!-- row 3 -->
         <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12" style="display:flex;flex-direction:column;">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>MTD Salesman yang Sudah Visit & Realisasinya</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <!--  -->
                            </div>
                        </div>
                        
                        <ChartVisitComponent
                            v-if="!state.isFetching"
                            :endpoint="state.endpoints.row3Left"
                            :params="queryParams()"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-auto">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>MTD Salesman yang Sudah Visit & % Kelengkapan Surveynya</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <!--  -->
                            </div>
                        </div>
                
                        <ChartPercentageComponent
                            v-if="!state.isFetching"
                            :endpoint="state.endpoints.row3Right"
                            :params="queryParams()"/>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- row 4 -->
        <div
            v-if="!state.isFetching"
            class="row justify-content-end mt-4">
            <!-- left -->
            <div class="content-card col-lg-6 col-md-12" style="display:flex;flex-direction:column;">
                <div class="card">
                    <div class="card-body d-block">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Realisasi visit Perdistributor & Kabupaten</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <!--  -->
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.endpoints.row4Left"
                                :params="queryParams()"
                                :data="state.callback.row4Left"
                                :columns="state.columns.row4Left"
                                @update:data="handleUpdateDataRow4Left"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- right -->
            <div class="content-card col-lg-6 col-md-12" style="display:flex;flex-direction:column;">
                <div class="card">
                    <div class="card-body d-block">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>TOP 5 Salesman</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <!--  -->
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.endpoints.row4Right1"
                                :params="queryParams()"
                                :columns="state.columns.row4Right"
                                :paginate="false"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body d-block">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>BOTTOM 5 Salesman</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <!--  -->
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.endpoints.row4Right2"
                                :params="queryParams()"
                                :columns="state.columns.row4Right"
                                :paginate="false"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- row 5 -->
         <div class="row justify-content-end mt-4">
            <!-- left -->
            <div class="content-card col-lg-5 col-md-12" style="display:flex;flex-direction:column;">
                <div class="card">
                    <div class="card-body d-block">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Salesman Belum Termapping Toko</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <!--  -->
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.endpoints.row5Left"
                                :params="queryParams()"
                                :columns="state.columns.row5Left"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- right -->
            <div class="content-card col-lg-7 col-md-12 mt-auto">
                <div class="card">
                    <div class="card-body d-block">
                        <div class="row justify-content-end">
                            <div class="col-lg-6 col-md-12">
                                <strong>Area 49 Salesman Sudah Termapping Toko</strong>
                            </div>
                            <div class="col-lg-6 col-md-12 text-right mt-auto">
                                <!--  -->
                            </div>
                        </div>

                        <!-- list table -->
                        <div class="table-responsive text-nowrap mt-4">
                            <TableStandard
                                v-if="!state.isFetching"
                                :url="state.endpoints.row5Right"
                                :params="queryParams()"
                                :columns="state.columns.row5Right"
                                @errors="errorMessage"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         
    </div>
</template>
<script>
import {
    defineComponent,
    reactive,
    onMounted,
    ref,
    nextTick,
} from 'vue'
import TableStandard from '@/components/Molecules/TableStandard'
import FilterDistributor from '@/components/filter/FilterDistributor'
import ChartVisitComponent from './ChartVisitComponent'
import ChartPercentageComponent from './ChartPercentageComponent'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        TableStandard,
        FilterDistributor,
        ChartVisitComponent,
        ChartPercentageComponent,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: {
                row4Left: [ // realisasi visit per distributor & kabupaten
                    {
                        title: 'Nama Distributor',
                        dataIndex: 'distributor',
                        key: 'distributor',
                        customRender({ text, record }) {
                            return {
                                children: text,
                                attrs: {
                                    rowSpan: record.distributorRowSpan > 0 ? record.distributorRowSpan: 0,
                                },
                            }
                        },
                    },
                    {
                        title: 'Distrik Toko (Kabupaten/Kota)',
                        dataIndex: 'city',
                        key: 'city',
                        customRender({ text, record }) {
                            return {
                                children: <span class={record.isSubtotal ? 'font-weight-bold' : ''}>{text}</span>,
                            }
                        },
                    },
                    {
                        title: 'Record Count',
                        dataIndex: 'total',
                        key: 'total',
                        customRender({ text, record }) {
                            return {
                                children: <span class={record.isSubtotal ? 'font-weight-bold' : ''}>{text}</span>,
                            }
                        },
                    },
                ],
                row4Right: [ // TOP OR BOTTOM 5 salesman
                    {
                        title: 'Full Name',
                        dataIndex: 'fullname',
                    },
                    {
                        title: 'Nama Distributor',
                        dataIndex: 'distributor_name',
                    },
                    {
                        title: 'Record Count',
                        dataIndex: 'record_count',
                    },
                ],
                row5Left: [ // Salesman belum termapping toko
                    {
                        title: 'Nama Distributor',
                        dataIndex: 'distributor_name',
                    },
                    {
                        title: 'Full Name',
                        dataIndex: 'fullname',
                    },
                    {
                        title: 'Username',
                        dataIndex: 'email',
                    },
                ],
                row5Right: [ // Area 49 salesman sudah termapping toko
                    {
                        title: 'Nama Distributor',
                        dataIndex: 'distributor_name',
                    },
                    {
                        title: 'Full Name',
                        dataIndex: 'fullname',
                    },
                    {
                        title: 'Username',
                        dataIndex: 'email',
                    },
                    {
                        title: 'MDXL Vendor Toko ID',
                        dataIndex: 'customer_code',
                    },
                    {
                        title: 'Customer: Account',
                        dataIndex: 'customer_name',
                    },
                    {
                        title: 'Kabupaten/Kota Toko',
                        dataIndex: 'customer_city',
                    },
                ],
            },
            endpoints: {
                row1Left: '/api/dashboard-ats/total',
                row1Right: '/api/dashboard-ats/grafik-salesman-visit',
                row2Current: '/api/dashboard-ats/grafik-salesman-visit-survey',
                row3Left: '/api/dashboard-ats/grafik-mtd-salesman-visit',
                row3Right: '/api/dashboard-ats/grafik-mtd-salesman-visit-survey',
                row4Left: '/api/dashboard-ats/realisasi-distributor-toko',
                row4Right1: '/api/dashboard-ats/top5-salesman',
                row4Right2: '/api/dashboard-ats/top5-bottom',
                row5Left: '/api/dashboard-ats/salesman-unmapping-customer',
                row5Right: '/api/dashboard-ats/salesman-mapping-customer',
            },
            callback: {
                row4Left: [],
            },
            isFetching: false,
            params: extractQueryParams({
                month: moment().format('YYYY-MM-DD'),
                distributor: [],
                page: 1,
                "per-page": 10,
            }),
            total: {
                loading: false,
                registered_salesman: 0,
                visit_salesman: 0,
            },
        })

        const handleUpdateDataRow4Left = (data) => {
            const dataSource = [];
            data.forEach((item, itemIndex) => {
                item.cities.forEach((city, cityIndex) => {
                    dataSource.push({
                        distributor: item.distributor,
                        city: city.city,
                        total: city.total,
                        distributorRowSpan: cityIndex === 0 ? item.cities.length + 1 : 0,
                        isSubtotal: false,
                    });
                });
                dataSource.push({
                    distributor: '',
                    city: 'Subtotal',
                    total: item.subtotal,
                    distributorRowSpan: 0,
                    isSubtotal: true,
                });
            });

            state.callback.row4Left = dataSource
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.month) {
                state.params.month = moment(state.params.month).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        // handle vue
        onMounted(() => {
            // 
        })

        return {
            state,
            errorMessage,
            fetchDataList,
            handleUpdateDataRow4Left,
            queryParams,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>
